#myBtn {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #233684;
  bottom: 76px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  right: 5%;
  text-decoration: none;
  text-align: center;
  color: rgb(236, 223, 223);
  line-height: 20px;
  font-size: 17px;
  z-index: +2;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}