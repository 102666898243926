.fullservices{
    overflow-x: hidden;
}
.sevices-head{
    margin-top: 20px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}

#mobile{
    background-image: linear-gradient(rgba(19, 12, 12, 14), #21222298), url('../image/MobileAppDev.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
     /* opacity: 0.5;  */
}
#cloudid{
    background-image: linear-gradient(rgba(19, 12, 12, 14), #21222298), url('../image/cloudbac.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}
#datamig{
    background-image: linear-gradient(rgba(19, 12, 12, 14), #21222298), url('../image/datamib.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}
#dataint{
    background-image: linear-gradient(rgba(19, 12, 12, 14), #21222298), url('../image/businessint.png');   
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}
#webdev{
    background-image: linear-gradient(rgba(19, 12, 12, 14), #21222298), url('../image/webappdev.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(237, 236, 240);
}
#reportings{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/datarep.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(7, 5, 14);
}
#quality{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/qualityck.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(249, 249, 250);
}
#appser{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/modernization.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(249, 248, 252);
}
#reportings{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/reportback.jpg');    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(248, 247, 247);
}
#digit{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/digitmarket.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color:white;
   
      /* position:absolute;   */
}
 /* .letter{
    position:relative;
}   */

#webapp{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/webappdev.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(248, 248, 248);
    
}
#tech{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/consultant.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(248, 248, 248);
    /* height: 1100px; */
    

}
#ites{
    background-image: linear-gradient(rgba(8, 8, 7, 2), #11101073), url('../image/itesback.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(248, 248, 248);
    
}
.top-head{
    padding-top: 30px;
    margin-top: 5%;
    
    text-align: center;
    
}
.pagehead{
  
    font-size:35px;
    font-weight: 500;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.app-content{
    margin-top: 24px;
    font-size: 20px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.lihead{
    font-size:22px;
    font-weight: 400;
}
.service-ul{
    font-size:17px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}

.services-image{
    width: 100%;
    height: 300px;
    /* background: transparent; */
}
.sub-apphead{
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
    /* color: rgba(0, 0, 0, 0.781); */
}
.sub-1apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left: 30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.Disub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.Qusub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.ITsub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.STsub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.DMsub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.CCsub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.BIsub-apphead{
    font-size: 25px;
    font-weight: 500;
    margin-left:30px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.app-develophead{
    font-size: 22px;
    font-weight: 400;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
    /* color: rgba(0, 0, 0, 0.692); */

}
.subhead-appcontent{
    font-size: 17px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
    font-weight: 400;
  
}
.ios{
    width:70%;
    height:300px;
   }
.iosrow{
    text-align: center;
}
.mobflow{
    width: 70%;
    height: 400px;
}
.head-con{
    font-size: 20px;
}
.appdevelop-box{
    border-bottom:5px solid rgba(0, 0, 0, 0.479) ;
}
.service-heading{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
}
.webapplication-box{
    border-bottom:3px solid lightgray ;
}
.docs{
    width: 100%;
}

.mob-head{
    text-align: center;
    font-size: 22px;
}
.li-content{
    font-size: 22px;
}
.mob-row{
    align-items: center;
    text-align: center;
}
.staffalign{
    /* margin-left: -20px; */
    padding: 0px 40px;

}
.mobalign{
    /* margin-left: -20px; */
    /* padding-top:30px; */
    padding: 0px 40px;
}
.mobaligns{
    /* margin-left: -20px; */
    /* padding-top:50px; */
    padding: 0px 40px;
}
.mor-row{
/* margin-top: 20px; */
}
.itescnt{
    /* margin-top: 20px; */
}
.cntrows{
    /* margin-top: 15px; */
}
.mob-newimg{
    /* position: relative; */
    height:100%;
    width:100%;
    /* padding: 5px 10px; */
}
/* .algn-row{
    height: 75px;
} */
.img-algn{
    /* padding: 5px 10px; */
}
.allitems{
    justify-content: center;
    align-items: center;
}
.imgcol{
    margin-left: 120px;
    width: 100%;
}
.fullrow 
{ 
    align-items: center; 
    height:auto; 
    padding-top: 3%;  
}
.stfullrow{
    align-items: center; 
    height:auto; 
    padding-top: 1%;  
    padding-left: 15%;
}
/* .fullrows{
    border: 1px solid gray;
    background-color: rgba(0, 0, 0, 0.26);
} */
.li-content{
    padding: 7px 0;
    font-size: 17px;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
    font-weight: 500;
}
.title-box{
height:40px;
}

.line-cnt{
    padding-top:10px;
    /* list-style-type: none; */
}
#image-container{
    display: flex;
    /* justify-content: space-between; */
    margin-left: -3px;
}

@media (max-width: 993px) {

    .mobalign{
        margin-left: unset;
    }

    .staffalign{

        margin-left: unset;
    }
.title-box{
    height: unset;
}
    .fullrow{
        height: auto;
        text-align: center;
    }
  
  
    #tech{
    height: auto;
    }
   
    /* .top-head {
        margin-top:0px;
    } */
   .app-content{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 17px;
   }
   ul.line-cnt {
   display: inline-block;
   text-align: left;
   }
   .Disub-apphead,.Qusub-apphead,.ITsub-apphead,.STsub-apphead,.DMsub-apphead,.CCsub-apphead,.BIsub-apphead,.app-develophead{

    margin-left: 0px;
    text-align: center;
   }
  

}
@media (max-width: 992px) {
   
#image-container{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


}
@media (max-width: 991px) {
.mob-newimg{
    width: 150px;
    height: 150px;
    padding: 15px 20px;
    
}
}