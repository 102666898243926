
 .nav-link{
    float: right; 
    display: flex; 
    flex-direction: row; 
    list-style-type: none;
    font-size: larger;
    padding: 5px 16px 7px 7px;
    margin-top:1%;
    margin-right:30%;
 
    
} 

.newlogo{
    font-size: 20px;
    margin-left:20%;
    margin-top: 1%;
    
}
.new-navbar{
    height: 15px;
    background-color: black;
    color: gray;
    position: fixed;
   z-index: 3000; 
   width: 100%;
   top:0;
   display: none;
}

.img-logo
{
    border-radius: 50px;
}
.back-btn
{
    cursor: pointer;
    position: absolute;
}
.delete-dir-style{
    color: #662626;
    cursor: pointer;
    margin-left: 10%;
}
.header-container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
display: contents;
}
.header-logo{
    margin-left: 3%;
    line-height:30px;

}
.header-nav {
    background-color:#595eff;
    height: 75px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: +3;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%), 0 13px 24px -12px rgb(0 0 0 / 57%);
    color: white;
}
label.logo{
    color:rgb(48, 45, 45);
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
}
.header-nav .header-ul{
    float: right;
    margin-right:200px;
}
.com-head{
    padding-right:15px;
}
  .header-nav .header-ul .header-list{
    display: inline-block;
    line-height: 80px;
     /* margin:0 7px;  */
    color:white;
    height: 75px;
font-size: 17px;
font-weight: 500;
cursor: pointer;
padding: 0px 15px 0 15px;

font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}  
.header-nav .header-ul .header-lists{
    display: inline-block;
    line-height: 80px;
     margin:0 2px; 
    color:white;
font-size: 17px;
font-weight: 500;
cursor: pointer;
padding: 0;

font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}
/* .active{
    text-decoration: underline;
} */

/* .header-nav .header-ul .header-list.active{
    background-color: blue;
    color: white;
} */
.header-nav .header-ul .header-list:hover{
    /* background-color: #9f9f9f;   
     */
    color: rgb(248, 209, 136);
    text-decoration: none;
}
.header-nav .header-ul .header-list .com-head:hover{
    /* background-color: #9f9f9f;   
     */
    color: rgb(248, 209, 136);
    text-decoration: none;
}

.ul-link{
color:rgb(46, 44, 44);
font-size: 17px;
}
.checkbtn{
    font-size: 30px;
    color: rgb(27, 26, 26);
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}
.LogoName
{
    position: fixed;
    color: rgb(29, 28, 28);
    font-weight: 600;
    font-size: 4vh;
    margin-top:10px;
    margin-left: 130px;
}
.new-icon{
    height: 50px;
}

.LogoNum{
    margin-left:0%;
    margin-top: 20px;
    padding:0px 800PX 0px 0px;
    font-size: 18px;
    
}


.header-flex
{
    display: flex;
    justify-content: space-evenly;
}
@media (max-width: 952px) {
    label.logo{
        font-size: 30px;
        padding-left: 50px;
    }
    .ul-link{
        font-size: 16px;
    }
     .new-navbar{
        height: 70px;
        top: 0;
        width: 100%;
    } 
    
   
}
@media (max-width: 1300px) {
 .checkbtn{
     display: block;
 }
 .LogoName{
     margin-left: 10px;
     margin-top:10px;
 }

 .header-ul{
     position: fixed;
     width: 100%;
     height: 100vh;
     background-color:#595eff;
     margin-top:72px;
     left: -100%;
     text-align: center;
     transition: all .5s;
     z-index: +1;
     padding: 0%;
 }
 
 .header-nav .header-ul .header-lists{
     display: block;
     text-decoration: none;
     width: 100%;
     height: 50px;
     
 }
 /* .header-nav .header-ul .header-list .active{
     border-bottom: 5px solid white;
 } */
 .ul-link{
     font-size: 20px;
    }
    #check:checked ~ .header-ul{
        left: 0;
        z-index: +1;
    }
    .logout{
        display: none;
    }
    .logout2{
        display: block;
    }
    .header-logo{
        margin-left: 3%;
        margin-top: 3%;
    
    }
    .d-content{
        width:70%;
    }
}
/* .donatesymbol{
    width: 16%;
    margin-left: 0%;
    position: relative;
} */

@media only screen and (max-width: 600px) {
    .donatesymbol{
        width: 20px;
        margin-left: 0%;
        position: relative;
    }
  }
 

 
  .icon-bar{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  

  .dropdowns {
    display: inline-block;
    
 
  }
 
  
  .d-content {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background: rgb(1 1 1 / 42%);
    display: none;
    left: 1px;
    position: absolute;
    top:75px;
    width: 100%;
    z-index: 10;
}
  .d:hover .d-content{
      display: block;
  }
  .column {
    margin: 45px 0px 45px 0px;
  
}
 
.column a {
    display: block;
    float: none;
    text-align: left;
    padding: 4px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 13px;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}
.column a:hover {
    background-color: #9f9f9f;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    /* font-family:monospace;
    text-decoration: underline; */
}
 
  .megarow:after{
content: '';
display: table;
clear: both;
  }

  .dropdowns-content .a {
    color: black;
    
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdowns-content .a:hover { 
      background-color: #9f9f9f;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
     border-radius: 10px;
     /* font-family:monospace;
     text-decoration: underline; */
}
  
  .dropdowns:hover .dropdowns-content {
    display: block;
  }
  
  
.dropdowns {
    position: relative;
    display: inline-block;
  }
  
  .dropdowns-content {
    display: none;
    height: 41px;
    /* padding-top: 0px; */
    position: absolute;
    background: rgba(1, 1, 1, 0.089);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdowns-content .a {
    color:white;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
   
  }
 
  
  .dropdowns:hover .dropdowns-content{
    display: block;
  }
  @media screen and (max-width: 992px) {
    .dropdowns {
        display: inline-block;
        width: 100%;
      }
      .ex-link[disabled]{
          pointer-events: none;
      }
      /* .header-nav .header-ul{
          margin-top: 30px;
      } */
      .header-nav .header-ul .header-lists {
        /* display: inline-block; */
        line-height: 80px;
        /* margin: 0 41px; */
        list-style: none;
        color: white;
        font-size: 17px;
        font-weight: 500;
        width: 100%;
        height: 70px;
        cursor: pointer;
        padding: 20px;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
       
    }
      .column {
        margin: 0px;
      }
     

      .project {
        /* border: 1px solid lightslategray; */
        /* width: 70%; */
        width: 100%;
        padding: 20px;
        /* margin-left: 90px; */
        /* box-shadow: 5px 5px 30px 7px rgba(10, 10, 10, 0.25), -5px -5px 30px 7px rgba(140, 22, 218, 0.22); */
        background-color: lightgray;
        /* border-radius: 20px; */
        /* margin-left: 40px; */
        border-radius: 20px;
      
        
        margin: 0%;
        /* margin: 20% 0% 0% 15%; */
    }
     
  }

    .act{
        color: rgb(248, 209, 136);
    text-decoration: underline;
} 

@media screen and (max-width: 382px){
    .header-lists{
        width: 100%;
        height: 30px;
        padding: auto;
    }
}
/* .color .acts{
    background-color: lightgray;
} */