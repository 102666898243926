.full-contact{
    /* background-image: linear-gradient(rgba(6, 6, 6, 0.527), #11101073), url('C:\Users\Dev11\projectnew\src\image\blue.jpg');    */

    /* background-color:rgba(211, 211, 211, 0.26); */


}
.contact-icon{
   font-size: 75px;
     
   color: rgb(10, 10, 10);
   
    
}



.contactus-row{
    margin-top:0;
    
  
}
/* .col-contact{
    align-items: center;
    text-align: center;
    justify-content: center;
    
} */
.contactnew-card{
    text-align: center;
    /* margin-left: 50px; */
   /* height: 100%;
   width: 350px; */
   margin-top: 20px;
   padding: 20px;
    background-color:#ffffff;
    border-radius: 10px;
    box-shadow: 0px 20px 7px 5px rgb(0 0 0 / 12%);
    height: 290px;
    word-wrap: break-word;
    /* width: 290px; */
}
/* .contactnew-card:hover{
    background-color: #4773a5;
    color: white;
    
} */
/* .contactnew-card:hover .contact-icon{
color: white;
} */
.icon-firsttxt{
    font-size: 20px;
    font-weight: 500;
}
.icon-subtxt{
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
    font-weight: 500;
}
/*contact img container */

.usimag{
    width:100%;
    height: 500px;
    position: absolute;
    
}
#inputbox{
height: 40px;

}
#textfull{
    height: 100px;
}
/* .contactimg-box{
    position:absolute;
} */
.inner-imgtxt{
    position:relative;
    color: white;
}
.arange-txt{
    margin-top: 20px;
  
}
/* .contactnew-card:hover .innertxt-head {
    color: white;
}
.contactnew-card:hover .innertxt-content{
    color: white;
}
.contactnew-card:hover .innertxt-last{
    color: white;
}
.contactnew-card:hover .inner-contacticon{
    color: white;
} */
.innertxt-head{
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    /* margin-left: 50px; */
    font-size: 20px;
    font-weight: 500;
    color: rgb(14, 14, 15);
    
}
.innertxt-content{
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    /* margin:20px 0 0 50px ; */
    font-size:17px;
    font-weight: 500;
    color: black;

}
.innertxt-last{
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    /* margin:0 0 0 50px ; */
    font-weight: 500;
    font-size: 17px;
    color: black;
    word-wrap: break-word;
}
.inner-contacticon{
    font-size: 50px;
    /* margin-left: 42px; */
    color: black;

}
.watsapcon:hover{
color: rgb(24, 228, 17);
}
.instacon:hover{
color: rgb(160, 4, 82);
}
.fbcon:hover{
color: royalblue;
}
.linkcon:hover{
color: royalblue;
}
.maincontact-content{
    /* background:linear-gradient(to bottom, rgba(199, 170, 9, 0.5),rgb(201, 61, 171)); */
    padding:30px 0 30px 0;
}
/* .contact-row{
    height: 500px;
} */
/* contact image container end */


/* inner form container */

.innercon-form{
    margin-top:20px;
}
.form-contactdiv{
    /* background-color:whitesmoke; */
}
.cntct-btn{

    font-size: 17px;

    padding: 4px;

    border-radius: 5px;

    /* border: none; */

    background:none;

    width: 90px;

    border-color:rgba(0, 0, 0, 0.925);

    color:#fff;

    margin-left:102px;
    margin-top: 18px;
    transition: 0.5s;

    position: relative;

    overflow: hidden;

}

.btn1{
color:white;
}

.btn1:hover{
    background-color:white;
    color:black;
    font-weight: bold;
}

.btn1::before{
    border-radius: 0 0 50% 50%;
    top:0;
}

.btn1:hover:before{
    height: 180%;
}

.cntct-btn::before{
    content: "";
    position: absolute; 
    left: 0; 
    width: 100%; 
    height: 0%; 
    background: linear-gradient(87deg, rgb(89, 92, 255), rgb(128 239 255)); 
    z-index: -1; 
    transition: 0.5s;
}

@media (max-width: 952px) {

    .Fcard-cn{
        padding-left: unset !important;
    }
    .frontcomrow{

    }
/* .card-1{
    margin-left:15px !important;
} */
/* .topcontainer{
    padding: 0 25px 0 25px !important;

} */

    /* .icon-divbox{
        flex-direction: column;
    } */
.change-btn{
    text-align: center;
    align-items: center;
}
    .innertxt-head{
        /* margin-left: 25px; */
    }
    .innertxt-content{
        /* margin-left: 28px; */
    }
    .innertxt-last{
        /* margin-left:30px ; */
    }
    /* .contact-row{
        height:100%;
    } */
    .talkimg{
        width:100%;
    }
    .talkpage{
        height: 100%; 
    } 
    .tec-cards{
        
        width: 70%;
        margin: 10px 55px;
    } 
    /* .contactnew-card{
       
        width: auto;
        margin-left: auto;
    } */
}
.talkimg{
    height:400px;
}
.talktxt{
    margin-top: 30%;
    align-items: center;
    text-align: center;
    font-size:45px;
    font-weight: 700;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}
.talkrow
{
    /* position:relative; */
    /* margin-top: 5%; */
   
}
.talk-fluid{
     /* background-color: rgba(109, 108, 108, 0.199); */
}
.fulltalk{
    /* position:absolute; */
    /* top:80%; */
    /* width: 100%; */
}
.talkpage{
    height: 100vh;
    margin-top: 70px;
}
/* tch page start */
/* .techpage{
    height: 90vh;
} */

.card-container{
    width: 100%;
    height: 100%;
    /* display: flex;
    flex-flow: row wrap; */
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out;
}
.Fcard-cn{
    
    /* padding-left:25px; */
    text-align: center;
}
.card{
    align-self: flex-start;
    position: relative;
    /* width: 285px;
    min-width: 275px; */
    margin: 1.25rem 0.75rem;
    background: white;
    box-shadow: 5px 9px 14px rgb(0 0 0 / 12%);
    transition: all 300ms ease-in-out;
}
.card .card-img {
    /* visibility: hidden; */
    width: 100%;
    height: var(--card-img-height);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.card .card-img-hovered {
    --card-img-hovered-overlay: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    transition: all 350ms ease-in-out;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: absolute;
    height: var(--card-img-height);
    top: 0;
}
.card .card-info {
    position: relative;
    padding: 0.75rem 1.25rem;
    transition: all 200ms ease-in-out;
}
.card .card-info .card-about{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    transition: all 200ms ease-in-out;
}
.card .card-info .card-about .card-tag {
    width: 60px;
    max-width: 100px;
    padding: 0.2rem 0.5rem;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #505f79;
    color: #fff;
}
.card .card-info .card-about .card-tag.tag-news{
    background: #36b37e;
}
.card .card-info .card-about .card-tag.tag-deals{
    background: #ffab00;
}
.card .card-info .card-about .card-tag.tag-politics{
    width: 71px;
    background: #ff5630;
}
.card .card-info .card-title{  
    z-index:10;
    font-size:1.5rem;
    padding-bottom:0.75rem;
    transition:all 350ms ease-in-out;
}
.card .card-info .card-creator
{
    padding-bottom:0.75rem;
    transition:all 250ms ease-in-out;
}
.card:hover{
    cursor:pointer;
    box-shadow: 0px 15px 35px rgba(227, 252, 239, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
    transform: scale(1.025);
    transform: translateY(-25px);
    transition: 0.5s;
}
.card:hover .card-img-hovered{
    --card-img-hovered-overlay: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65));
    height:100%;
}
.card:hover .card-about, .card:hover .card-creator{
    opacity: 0;
}
.card:hover .card-info{
    background-color: transparent;
}
.card:hover .card-title{
    color: #ebecf0;
    transform: translate(0, 30px);
}
.card-1 .card-img, .card-1 .card-img-hovered{
    background-image: var(--card-img-hovered-overlay), url(../image/imgone.jpg);
}

.card-2 .card-img, .card-2 .card-img-hovered
{
    background-image: var(--card-img-hovered-overlay), url(../image/sushma.jfif);
}
.card-3 .card-img, .card-3 .card-img-hovered{
    background-image: var(--card-img-hovered-overlay), url(../image/imgtwo.jpg);
}
.card-4 .card-img, .card-4 .card-img-hovered{
    background-image: var(--card-img-hovered-overlay), url(../image/imgfour.png);
}
.tchimg
{
    width: 100%;
    height: 300px;
}
.card-1{
    /* margin-left: 96px; */
    
}
.card-cn{
    text-align: center !important;
    align-items: center;
    /* padding-left:30px; */
}
.techpage{
    height:auto;
    /* background-color: whitesmoke; */
}


/* technologies page  start */

.tecfirst{

    margin-top: 36px;

}
.tec-second{
    margin-top: 3px;
}
.tec-thrd{
    margin-top:3px;
}
.tec-four{
    margin-top:3px;
    margin-bottom:4px;
}
.tecno-img{
    text-align: center;
    padding-top: 30px;
}
.tecno-pic{

    
text-align: center;
height: 130px;
    width:130px;

    padding: 10px;

}
.tecno-picaws{
    height: 130px;
    width:120px;

    padding:20px 20px 10px 20px;
}
.tec-cards{

    /* border: 1px solid gray; */

    box-shadow: 0px 15px 35px rgba(227, 252, 239, 0.425), 0px 5px 15px rgba(0, 0, 0, 0.171);

    background-color:white;
    height: 230px;

    /* padding: 20px; */

}



.tecno-name{

    text-align: center;

    font-size:22px;

    font-weight: 500;

    padding-bottom: 10px;

}

.tec-cards:hover{

    

    transform: scale(0.8); 

    transition: 0.5s;

}
@media (max-width:290px){
    
    .contactnew-card{
        height: auto;
    }
}

.instacon{
    color: black;
    
}
.fbcon{
    color: black;
}
.linkcon{
    color: black;
}