body{
    overflow-x:hidden;
    overflow-y: unset;
    /* background-color: #f4f4f4; */
    /* background:linear-gradient(135deg, #3e2856, #684180) !important;  */
    }  
    
    /* Carousel css */
    .last-carousel{
        margin-top: -7px;
        border: 2px solid lightgray;
        padding: 5px;
    }
    
    #boot-img{
        width: 1600px;
        height:400px;
        position:relative;
    } 
    .text{
        position:absolute;
        width: 500px;
        height: 500px;
        color: white;
        text-align: center;
        justify-content: center;
        top:2%;
       
    }
    .text-content{
        margin-top: 25%;
    }
    #newbtn{
        padding: 15px;
        border-radius:20px;
        background-color: rgb(74, 235, 221);
        color: slateblue;
        border:none;
        font-weight: 500;
        text-align: center;
    }
    .fullabout{
    
        /* margin-top: 80px; */
    
        height:100%;
    padding-bottom: 8px;
    
    }
    .firstabout{
        height: 75px;
    }
    .iconnxt{
        font-size: 22px;
    }
    .carousel-container{
        margin-top:75px;
        background-image: url(../image/wavebg.jpg);
        background-repeat: no-repeat;
        background-size:100% 100%;
        /* padding: 30px 0 30px 0; */
    
    }
    /* Carousel container end */
    
    
    .new-container-fluid{
        width: 100%;
        
    }
    .mis-icon{
        height: 80px;
        width: 80px;
        color: black;
        border-radius: 50%;
        border:1px solid lightslategrey;
    }
    .mission{
        text-align: center;
        box-shadow: 5px 5px 30px 7px rgba(240, 167, 32, 0.25), -5px -5px 30px 7px rgba(121, 14, 14, 0.22);
        padding: 20px;
        /* border-radius: 20px; */
    
    }
    .list-mission{
        text-align: center;
        box-shadow: 5px 5px 30px 7px rgba(240, 167, 32, 0.25), -5px -5px 30px 7px rgba(121, 14, 14, 0.22);
        padding: 20px;
        height:100%;
        /* border-radius: 20px; */
    }
    .aboutmis-head{
    font-size:30px;
    font-weight: 500;
    color:black;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    .aboutmis-content{
    font-size: 16px;
    font-weight: 500;
    text-align:justify;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    padding:30px;
    }
    .aboutus-txt{
        font-size: 17px;
        font-weight: 500;
        color: black;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    } 
    .onestrow{
         margin-top:-9px;
    }
    .secstrow{
        margin-top:-15px;
    }
    /* .fullabout{
        border-bottom: 2px solid rgba(0, 0, 0, 0.281);
    } */
    
    .ex-icon {
       
       
        /* border-radius: 50%; */
        line-height: center;
        text-align: center;
        font-size: 22px;
         cursor: pointer;
      
      }
      .close-icon{
          font-size: 24px;
          float: right; 
         margin-right: 5px;
         padding: 0 10px 0 10px;
        
      }
      .close-icon:hover{
          border: 1px solid whitesmoke;
          background-color: rgb(54, 53, 53);
          color: white;
      }
      .reveal{
        text-align: center;
        position:relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 30px;
      
      }
      .expand-content{
       /* // background-color: cornsilk; */
        position:fixed;   
        height: 100vh;  
        top:0%;
        width: 100%;
        background-color:rgba(19, 18, 18, 0.61);
        z-index:+9;
      }
    
      .expand-div1{
        /* background: rgba(1, 1, 1, 0.089);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px); */
        background-color:white;
        border-radius: 10px;
        height: 220px;
        /* width:700px; */
        width:30%;
        margin: 20% 0 0 35%;
        /* color: white; */
        
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color:rgb(14, 13, 13);
        position: fixed;
      }
      .expand-div2{
        background-color:white;
        border-radius: 10px;
        /* background-image: linear-gradient(rgba(6, 6, 6, 0.527), #11101073), url('C:\Users\Dev11\projectnew\src\image\vision.jpg'); */
        height: 220px;
        width: 30%;
        margin: 20% 0 0 35%;
            /* color: white; */
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color:rgb(12, 11, 11);
      }
      .expand-div3{
        /* background-image: linear-gradient(rgba(6, 6, 6, 0.527), #11101073), url('C:\Users\Dev11\projectnew\src\image\goals.jpg'); */
        background-color:white;
        border-radius: 10px;
        height: 240px;
        width:40%;
        margin: 20% 0 0 28%;
            /* color: white; */
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color:rgb(12, 11, 11);
      }
      .expand-div4{
        /* background-image: linear-gradient(rgba(6, 6, 6, 0.527), #11101073), url('C:\Users\Dev11\projectnew\src\image\digitmark.jpg'); */
        background-color:white;
        border-radius: 10px;
        text-align: center;
        height:180px;  
        width:30%;
        margin:20% 0 0 35%;
        /* color: white; */
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color:rgb(17, 17, 17);
      }
    .aboutmis-contents{
         text-align:center;
      justify-content: center;
      padding-top: 40px;
      font-size: 16px;
    font-weight: 500;
    }
    .aboutmis-contents2{
        /* text-align:center; */
     justify-content:space-evenly;
     padding: 70px 30px 0 30px;
     
     font-size: 16px;
    font-weight: 500;
    }
    
    .aboutmis-content1{
        text-align:center;
     justify-content: center;
     margin-top: 80px;
     font-size: 16px;
    font-weight: 500;
    }
    /* .aboutmis-content2{
        margin-top: 80px;
     font-size: 16px;
    font-weight: 500;
    } */
    
      /* .line{
          height:3px;
          background-color: rgba(19, 18, 18, 0.11);
          box-shadow: 5px 9px 14px rgb(0 0 0 / 12%);
      } */
    /* overlay container */
    
    .hoverimg{
        height: 250px;
        width:100%;
        border-radius: 10px;
    }
    
    .hovercon{
        
        position: relative;
        width:100%;
    }
    .non-over{
        position: absolute;
        top:40%;
        background-color: rgba(92, 71, 212, 0.37);
        width: 100%;
        height: 180px;
        border-radius: 10px 10px 0 0;
    }
    .nonover-text{
        font-size:22px;
        text-align: center;
    }
    .new-text{
        font-size: 35px;
        font-weight: 700;
    }
    .info-box{
        /* padding-top:20px; */
       margin-top: 6px;
    }
    .info-fullbox{
        padding:15px 0px 15px 0px;
    }
    .nonover:hover{
        display: none;
    }
    .info-text{
        
        font-size: 25px;
        color: rgba(11, 11, 12, 0.767);
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
      
       
    }
    
    /* .hoverimg{
        display:block;
        width:100%;
        height:400px;
    
    } */
    .overlay {
        position: absolute;
        bottom: 0;
        left:0;
        right: 0;
        background-color: rgb(14 14 15 / 73%);
        overflow: hidden;
        width: 100%;
        height: 35%;
        transition: .5s ease;
        border-radius: 10px;
    }
    .hovercon:hover .overlay{
        height:100%;
    
    }
    
    .hoverbox{
        align-items: center;
        text-align: center;
        padding: 0px 40px 40px 40px;
    }
    
    .hoverup-text{
    
        /* background-image: url(C:\Users\Dev11\projectnew\src\image\codeman.jpg); */
        /* background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed; */
        color: white;
        padding-bottom:13px;
        /* background: rgba(1, 1, 1, 0.274);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px); */
        /* background-color:rgba(211, 211, 211, 0.26); */
        
        
    }
    .career-con{
        padding-bottom: 25px;
    }
    .ptext{
        padding-top:30px;
        font-size: 25px;
        padding-bottom: 30px;
    }
    .textcol{
        /* padding: 30px 0px 10px 0px; */
    
    
    }

    .hovertext {
        word-wrap: break-word;
        font-size: 30px;
        font-weight: 500;
        color: white;
        text-align: center;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
        margin-top: 28px;
        text-shadow: 2px 2px black;
    }
    
    .styletext{
        word-wrap: break-word;
        width: 100%;
        margin-top:7%;
        color: white;
        font-size:22px;
        position: absolute;
        text-align: center;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* transform: rotate(-5deg)skewX(-5deg); */
    }
    .styletexts{
        word-wrap: break-word;
        width: 100%;
        margin-top:4%;
        color: white;
        font-size:22px;
        position: absolute;
        text-align: center;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* transform: rotate(-5deg)skewX(-5deg); */
    }
    /* .uptxt-head{
           padding: 2px;
    }
    .uptxt{
        padding: 2px;
    } */
    .style-content1{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 11%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content2{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 11%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content3{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 10%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content4{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 12%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content5{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 10%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content6{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 11%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content7{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 10%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .style-content8{
        word-wrap: break-word;
        text-align:start;
        
        font-size:17px;
        margin:21% 0 0 10%;
        width: 82%;
        font-weight: 450;
        
        line-height: 1.1em;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        
    }
    .secondtext{
        font-size: 17px;
        word-wrap: break-word;
    }
    
    /* overlay container end */
    
    .cardcon-text{
        width: 100%;
        word-wrap: break-word;
    }
    
    /*newhover card */
    
    
    
    
    
    
    
    
    
    
    .back-container{
        width: 100%;
        height:800px;
        background-image: url(../image/jop.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }
    .btnplace{
        padding-top: 50px;
    }
    .back-btn{
        padding: 15px;
        background: transparent;
        border:none;
        border-bottom: 2px solid whitesmoke;
        font-size: 25px;
        color: white;
    }
    .sec-bck{
        margin-top: 100px;
    }
    .backtxt-word{
        font-size:55px;
        font-weight: 700;
        
    }
    .back-border{
        background-color:rgba(246, 246, 248, 0.815);
        color: rgb(7, 6, 6);
        font-size: 18px;
        /* padding: 10px; */
    }
    .border-line{
        border-right: 1px solid rgb(19, 17, 17);
        font-weight: 700;
        text-align: center;
        height: 150px;
        
        
        
    }
    .contain-content{
        top: 50px;
        font-weight: 700;
        text-align: center;
        margin-top: 50px;
    }
    .back-border2{
        background-color:rgba(246, 246, 248, 0.815);
        color: rgb(12, 11, 11);
        font-size: 18px;
        /* padding: 10px; */
    }
    .border-line2{
        border-right: 1px solid rgb(14, 13, 13);
        font-weight: 700;
        text-align: center;
    }
    .back-border3{
        background-color:rgba(246, 246, 248, 0.836);
        color: rgb(7, 7, 7);
        font-size: 18px;
        /* padding: 10px; */
    }
    .back-border4{
        background-color:rgba(246, 246, 248, 0.562);
        color: white;
        font-size: 18px;
        /* padding: 10px; */
    }
    .border-line3{
        border-right: 1px solid rgb(32, 31, 31);
        font-weight: 700;
        text-align: center;
    }
    
    
    /* hover card container */
    
    
    .cardcol-text{
        word-wrap: break-word;
    font-size: 30px;
    font-weight: 500;
    margin-top:32px;
    text-align: center;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
    }
    .cardcolf-text{
        font-size: 30px;
    font-weight: 500;
    margin-top:2px;
    text-align: center;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
    }
    
    
    .clients-ncard {
        position: relative;
        width: 350px;
        height: 150px;
        margin-top: 70px;
        background-color:whitesmoke;
        border-radius: 10px;
        box-shadow: 5px 9px 14px rgb(0 0 0 / 12%);
        /* transition: 0.5s; */
        border: 1px solid
        rgba(119, 136, 153, 0.062);
    }
      .newclient{
    
          display: flex;
          flex-direction:row;
          justify-content: space-around;
    
      }
     
      /* .clients-ncard:hover{
        height: 250px;
      } */
      .imgbox{
        
        position: absolute;
        transform: translateY(-30%);
        width: 170px;
        height: 170px;
        background-color: black;
        border-radius: 10px;
        overflow: hidden;
        border: none;
        left: 25%;
      }
      .imgbox img{
        position: absolute;
        top:0;
        left: 0;
        height:100%;
        width:100%;
        object-fit:fill; 
       
        transition: 0.5s;
        border: none;
      }
      
      .clients-ncard:hover .imgbox {
        width: 170px;
        height: 170px;
        left:25%;
        border: none;
        transform: translateY(-50%);
        transition: 0.5s;
    
      }
     
      .clients-ncard .clients-nsaycontent{
        position: absolute;
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        /* align-items:center; */
      overflow: hidden;
      /* background-color: rgba(255, 255, 255, 0.452); */
      }
    
      .clients-ncard .clients-nsaycontent .client-ndetails{
        padding: 80px;
        text-align: center;
        width: 100%;
        transition: 0.5s;
        transform: translateY(100px);
      }
      .clients-ncard:hover .client-ndetails
      {
        transform: translateY(30px);
      }
      /* .clients-ncard:hover .clients-nsaycontent .client-details{
          transform: translateY(20px); 
          padding-bottom: 100px;
          
      } */
      /* .clients-ncard:hover .client-nname {
          font-size: 25px;
          font-weight: 600;
          color: black;
             
    
      } */
      .client-nname{
        font-weight:400;
        font-size:20px ;
        color: rgb(10, 10, 10);
        line-height: 1.25em;
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif; 
      }
    
      .client-njob{
        font-size: 17px;
        font-weight: 500;
        opacity: 0.5;
      }
    
      .client-nmsg{
        font-size: 18px;
        text-align: justify;
        color: rgba(19, 20, 20, 0.699);
      }
     
      .client-nreviewbtn{
        padding: 10px;
        border-radius: 5px;
        border: none;
        background-color: rgb(203, 202, 204);
        color: rgb(17, 16, 16);
      cursor: pointer;
      }
      /* .client-hovercard2{
          margin-left: 30px;
      } */
      .contact-section{
        background-image: url('../image/wavebg.jpg');    
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding: 31px 0px 28px 0px;
      }
      /* hover card design end */
    .above-head{
        margin-top:31px;
        font-size: 30px;
        font-weight: 500px;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif ;
    }
    .above-content{
        text-align: center;
    }
    .givingcnt{
        margin-top: 17px;
        text-align: center;
    }
    .lifecnt{
        margin-top: 18px;
        text-align: center;
    }
    .above-text{
        
        font-size:17px;
        font-weight: 500;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    /*empty container design */
    
    .bottom-container {
        width: 100%;
        height:auto;
        padding-bottom:52px;
        /* background-image: url(C:\Users\Dev11\projectnew\src\image\proback.jpg); */
        background: linear-gradient(87deg, rgb(89, 92, 255), rgb(128 239 255));
        background-repeat: no-repeat;
        background-size: cover;
        /* background-attachment: fixed; */
        position: relative;
    }
    .pro-box1{
        height:150px;
        width: 100%;
        border:2px solid rgb(165, 217, 207);
        background: white;
        text-align: center;
        word-wrap: break-word;
    }
    .pro-box2{
        height:150px;
        width: 100%;
        border:2px solid rgb(235, 165, 36);
        background: white;
        text-align: center;
        word-wrap: break-word;
    }
    .pro-box3{
        height:150px;
        width: 100%;
        border:2px solid   #cb44359f;
        background: white;
        text-align: center;
        word-wrap: break-word;
    }
    .pro-box1:hover{
        height:150px;
        width: 100%;
        border:2px solid #f8fafa;
        background:rgb(165, 217, 207);
        
    }
    .pro-box1:hover .link1{
        color: white;
    }
    .pro-box2:hover{
        height:150px;
        width: 100%;
        border:2px solid #f8fafa;
        background:rgb(235, 165, 36);
        
    }
    .pro-box2:hover .link2{
        color: white;
    }
    .pro-box3:hover{
        height:150px;
        width: 100%;
        border:2px solid #f8fafa;
        background:#ef3941;
        
    }
    .pro-box3:hover .link3{
        color: white;
    }
    .bottom-context{
        background-color: rgba(151, 22, 238, 0.267);
        height: 150px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 17%;
    }
    .bot-btn{
        padding: 10px;
        border: none;
        background: transparent;
        font-size: 20px;
        color:white;
    }
    .bot-txt{
        padding-top: 20px;
        font-size: 3vh;
        color:white;
    }
    .pro-icon{
        height:40px;
        width: 40px;
    }
    .probox-row{
        margin-top:30px;
    }
    .project-head{
        margin-top:33px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color:black;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
        word-wrap: break-word;
        }
       
        .project {
            /* border: 1px solid lightslategray; */
            width: 45%;
            padding: 20px;
            /* margin-left: 90px; */
            /* box-shadow: 5px 5px 30px 7px rgba(10, 10, 10, 0.25), -5px -5px 30px 7px rgba(140, 22, 218, 0.22); */
            background-color: white;
            /* border-radius: 20px; */
            /* margin-left: 40px; */
            border-radius: 20px;
            margin: 12% 0% 0% 28%;
        }
        .pro-link{
            align-items: center;
            text-align: center;
            justify-content: center;
        }
        .link1{
            text-decoration: none !important;
            color:black;
            word-wrap: break-word;
        }
        .link2{
            text-decoration: none !important;
            color:black;
            word-wrap: break-word;
        }
        .link3{
            text-decoration: none !important;
            color:black;
            word-wrap: break-word;
        }
        .link1:hover{
            /* color: #1ee0b0; */
           

         
        }
        .link2:hover{
           

              /* color:rgb(235, 165, 36); */
             
        }
        .link3:hover{
            
            /* color: #cb4335; */
          
        }
        .flink{
            text-decoration: none;
            color:white;
        }
        .pro-name {
            margin-top: 53px;
         margin-left: 23%;
            font-size: 28px;
            text-align: left;
            /* text-align: center; */
            font-weight: 500;
            font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
            text-decoration: none;
            left: 50%;
        }
        .hide-content{
            margin-top: 23px;
        }
        .card-container{
            padding-bottom: 10px;
        }
    /* empty design container end */
    
    @media (max-width: 952px) {
        .frontcomrow{
            height: auto !important;
        }
        .cardcol{
            margin-top: unset !important;
        }
    .topcontainer{
        padding-left:2px;
    }
    .pro-box2{
        margin-top: 10px;
    }
    .pro-box3{
        margin-top: 10px;
    }
        /* .cntct-btns{
            margin-left: 2px;
        } */
.carousel-container{
    margin-top: auto;
    /* padding-bottom: 30px; */
}

.hoverup-text{
    margin: 48px 0 0 0;
}
        .contents{
            margin-top:auto;
        }
    .card{
        width: auto;
    }
        /* .topimg{
            margin-top: 3%;
            height:100px;
            width: 95%;
            border-radius: 10px;
        } */
    .textcol{
        /* margin-top: 30px; */
    }
    
    
        .bottom-context{
            background-color: rgba(255, 255, 255, 0.473);
            height: 160px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
                     }
        .bot-txt
        {
            padding-top: 20px;
            font-size: 20px;
        }
    .bottom-container{
        height:auto;
    }
    .project{
        margin: 8% 0% 10% 0%;
    }
    
    .text{
        top:53%;
        width: 350px;
        
    }
    
    .line{
       margin-top: 777px;
    }
    .sec-bck{
        margin-top: 50px;
    }
    .btnplace{
        padding-top: 20px;
    }
    .aboutmis-content{
        font-size: 12px;
        /* font-weight: 500; */
        text-align:justify;
        padding:30px 10px 0 0;
        }
    /* .cardcon-text{
        background-color:rgba(211, 211, 211, 0.541);
    } */
    
    
    .card-container{
    
        flex-direction: column;
        
        
    }
    /* .clients-ncard .clients-nsaycontent .client-ndetails{
        transform: translateY(18px);
    } */
    .clients-ncard{
        width:100% ;
    }
    .cardcol{
        margin-top: 84px;
    }
    .card-body{
        height: 260px;
    }
    .cards{
        width: 100%;
    }
    .text-content{
        margin: 0;
    }
    .info-text {
        font-size: 15px;
              }
    .style-content1{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content2{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content3{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content4{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content5{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content6{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content7{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    .style-content8{
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        /* font-size: 4.2vw; */
        margin: 26% 0 0 9%;
        line-height: 1em;
    }
    
    .overlay{
        height: 31%;
    }
    .bottomform1{
        margin-top: 50px;
    }
    .project
    {
    /* margin:0; */
    width: 100%;
    }
    /* .project-head{
        margin-left: 17%;
        font-size: 36px;
    }
    .pro-name{
        margin-left: 39%;
    } */
    .client-hovercard2{
        margin-left:0;
    }
    .newclient{
        flex-direction: column;
        height: 630px;
    }
    .reveal
    {
       flex-direction: column;
    }
    /* .topimg
    {
        height: auto;
    } */
    /* .frontcomrow{
        height:650px;
    } */
    }
    
    /* form Design start */
    
    .topcontainer{
        /* padding-left: 40px; */
    }
    .form-container{
        width: 100%;
        height:75vh;
    
    
    }
    .formchange{
        height: 60px;
      
    }
    .bottom-form1{
        margin-top:150px;
    }
    .bottom-form2{
        margin-top:20px;
    }
    .bottom-form3{
        margin-top:20px;
    }
    
      .optionbtn{
          height: 60px;
          width: 100%;
          border-color: lightgray;
          color: lightslategray;
      }
    .form-botomtext{
        margin-top: 20px;
        width: 100%;
        height: 130px;
    }
    .formbot-btn{
        padding:10px 0 10px 0;
        width:100px;
        border-radius: 10px;
        border:none; 
        background-color: mediumpurple;
        color: white;
    }
    /* form design end */ 
    .spread-img{
        width:100%;
        height:250px;
        
    }
    
    .spread-container{
        background-color: rgb(247, 241, 239);
        
    }
    .spread-content{
        justify-content: center;
        text-align: center;
        margin: 16% 0% 0% 1%;
        font-size: 22px;
        font-weight: 600;
    }
    .under-text{
        margin-left: 18%;
        font-size: 18px;
        font-weight: 400;
    }
    /* @media (max-width: 830px){
        .overlay{
            left: 40px;
        }
        .style-content{
            font-size:20px;
            margin-top:15%;
        }
        .imgbox{
            left:38%;
            
        }
        .clients-ncard:hover .imgbox {
        left: 38%;
        }
    } */
    /* @media (max-width: 280px){
        .overlay{
    left:5%;
        }
        .style-content{
            font-size:13px;
            margin-top:50%;
        }
    } */
    @media (max-width:290px){
        .imgbox{
            left:15%;
            
        }
        .overlay{
            height: 70px;
        }
    }
    @media (max-width:830px){
    
        .imgbox{
            left:38%;
            
        }
        /* .overlay{
            left: 35px;
        } */
        .clients-ncard:hover .imgbox {
            left: 38%;
            }
    }
    @media (max-width:450px){
        .imgbox{
            left:28%;
            
        }
        .clients-ncard:hover .imgbox {
            left: 28%;
            }
    }
    .lifehere
    {
        padding-bottom: 21px;
    }
    .givingback{
        padding-bottom: 22px;
    }
    .topimg{
        margin-top: 3%;
        height:450px;
        width: 95%;
        border-radius: 10px;
    }
    .topcontents{
        text-align: center;
        
    
    }
    .contents{
        margin:135px 0 0 0;
        /* text-shadow:0 0 3px rgba(119, 116, 116, 0.651); */
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
    }
    .foothead{
        font-size: 20px;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        font-weight: 700;
        color:white;
        text-decoration: none;
    
    }
    .foot-sympols{
        color: white;
    }
    .footheads{
        font-size: 20px;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        font-weight: 700;
        margin-left:121px;
        color:white;
        text-decoration: none;
    }
    .cntct-btns{
        /* font-size: 17px;
        padding: 10px;
        border-radius: 10px;
      
        background-color:rgba(211, 211, 211, 0.507);
        color: rgb(7, 7, 7); */
    }
    .footer-menu
    {
        font-size: 17px;
        font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif; 
        font-weight: 600;
        color: white;
        word-wrap: break-word;
        text-decoration: none;
    }
    .frontcomrow
    {
        padding-top:26px;
        /* padding-bottom: 30px; */
        height: 546px;
        /* box-shadow: 5px 9px 14px rgb(0 0 0 / 12%); */
        /* background-image: url(../image/wavebg.jpg);
        background-repeat: no-repeat;
        background-size:100% 100%; */
        
        
    }
    #serv-sec{
        box-shadow: 5px 9px 14px rgb(0 0 0 / 12%);
    }
    /* .lines{
       border-bottom: 3px solid rgb(252, 251, 251);
       box-shadow: 5px 9px 14px rgb(0 0 0 / 12%);
    } */
    .fullfooter{
        box-shadow: 5px 9px 14px rgb(0 0 0 / 12%);
        padding: 38px 0px 0px 0px;
         background-color: #6ca6ff;
         word-wrap: break-word;
         
        
    }
    .innercon-form{
        margin-top: 32px;
    }
    .all-foot{
       /* padding-bottom: 20px; */
    }
    /* .contact-section{
        margin-top: 45px;
        
    } */
    .landingtxt{
        word-wrap: break-word;
        font-size: 40px;
        font-weight:700;
        font-family:"Baskerville Old Face" ;
    }
    .landingtxts{
        font-size: 30px;
        font-weight:600;
        font-family:"Baskerville Old Face";
    }
    .about-head{
        text-align: center;
        margin-top: 32px;
        font-size: 30px;
        font-weight: 500px;
        font-family:"Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif ;
    }
    /* .valuerow{
        margin-top: -130px;
    } */
    .com-row{
        padding-left: 54px;
    }
    .exp-row{
        padding-left: 25px;
    }
    .companyrow{
        padding-left: 100px;
    }
    .pro-col{
        padding-left: 68px;
    }
    .tlk-row{
        padding-left: 6px;
    }
    @media (max-width:952px){
    
       .topcontainer{
           padding-left: unset !important;
       }

        .topimg{
            height: auto;
        }
        .com-row{
            /* padding-left: 10px; */
        }
    
        .exp-row{
            /* padding-left: 10px; */
            margin-left: 30px;
        }
    
        .goalclg{
            margin-top: -110px;
        }
        .goal-content{
            margin-top: -50px;
        }
        .companyrow{
    padding-left:15px;
        }
        .pro-col{
            /* padding-left:5px; */
            margin-left: -10px;
        }
        .valuerow{
            margin-top: -50px;
        }
        .innercon-form{
            margin-left: 40px;
            padding-bottom: 10px;
        }
       
    }
    
    
    /* #Technologies{
    
    
    
        margin-left: 90px;
     
     }
     
     #company{
     
     
     
         margin-left: 100px;
     
     }
     
     #Product{
     
         margin-left: 70px;
     
     }
     
     #talk{
     
         margin-left: 30px;
     
     }
     
     @media  screen and (max-width:991px) {
     
        
     
         #company, #Product,  #expertise, #Technologies{
     
          
     
            
     
             margin: 0;
     
         }
     
     
     
        
     
     } */