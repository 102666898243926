 .full-ncontact{
    /* background-image: linear-gradient(rgba(6, 6, 6, 0.527), #11101073), url('C:\Users\Dev11\projectnew\src\image\blue.jpg');    */
    /* height: 680px; */
    height:auto;
    margin-top: 6px;
}
/* .maincontact-ncontent{
    margin-left: -66px;
    
} */
/* .innertxt-last{
    margin-top: 10px;
} */
.innertxt-content{
    margin-top:16px;
}
.form-botomtext{
    padding:0px 10px;
    height: 100px;
    /* width: 85%; */
    border: 1px solid lightgray;
}
.form-botomtexts{
       padding:0px 10px;
        margin-top: 20px;
        width:300px;
        height: 130px;
        border: 1px solid lightgray;
    
}
.formchanges{
    height: 40px;
    /* width: 85%; */
}
.innercons-form{
    margin-top:36px;
}
.contact-row{
    height:100%;
    padding-top: 16px;
    padding-bottom: 21px;
}
.inners-imgtxt{
    /* float: right; */
    /* margin-left: 35%; */
}
.cntct-btns{

    font-size: 17px;

    padding: 4px;

    border-radius: 5px;

    /* border: none; */

    background:none;

  width: 90px;

    border-color: rgb(89, 92, 255);

    color: rgb(12, 12, 12);

    /* margin-left:95px; */

    transition: 0.5s;

    position: relative;

    overflow: hidden;

}

.btn2{

color:#000100;

}

.btn2:hover{
    background-color: linear-gradient(87deg, rgb(89, 92, 255), rgb(128 239 255));
    color:#fff;
    font-weight: bold;
}

.btn2::before{

    border-radius: 0 0 50% 50%;

    top:0;

}

.btn2:hover:before{

    height: 180%;

}

.cntct-btns::before{
    content: "";
    position: absolute; 
    left: 0; 
    width: 100%; 
    height: 0%; 
    background: linear-gradient(87deg, rgb(89, 92, 255), rgb(128 239 255)); 
    z-index: -1; 
    transition: 0.5s;

}
/* .butnsrow{
    text-align: center;
    align-items: center;
} */
.change-btn{
    /* text-align: center; */
    margin-top: 15px;
    margin-left: 30%;
    align-items: center;
}
#hometalk{
    width: 300px;
}
@media screen and (max-width: 992px) {

.maincontact-ncontent{
    height: 110vh;
    margin-left:unset;
}
.inners-imgtxt{
    /* float: unset; */
    /* margin-left: unset; */
/* padding-left: 100px; */
}
.formchanges{
width: 100%;
}
.form-botomtext{
width: 100%;
}
.change-btn{
    margin-left: unset;
}
.cntct-btns{
    margin-left: unset;
}
.inner-iconsbox{
    padding-left: 30px;
}
/* .lifehere{
    margin-top: 100px;
} */
/* .givingback{
    margin-top: 100px;
} */
.fullservices{
    margin-top: 34px;
}
}
/*
.contact-icon{
    font-size: 75px;
   
   color: blue;
   
    
}



.contactus-row{
    margin-top: 135px;
  
}
.contactnew-card{
    text-align: center;
   margin-top: 20px;
   padding: 20px;
    background-color: rgb(229, 232, 235);
    border-radius: 10px;
}
.contactnew-card:hover{
    background-color: black;
    color: white;
    
}
.icon-firsttxt{
    font-size: 22px;
    font-weight: 500;
}
.icon-subtxt{
    font-weight: 500;
}


.usimag{
    width:100%;
    height: 500px;
    position: absolute;
    
}

.inner-imgtxt{
    position:relative;
    color: white;
}
.arange-txt{
    margin-top: 50px;
  
}
.innertxt-head{
    margin-left: 50px;
    font-size: 25px;
    font-weight: 600;
    color: blue;
    
}
.innertxt-content{
    margin:20px 0 0 50px ;
    font-size:18px;
    font-weight: 500;

}
.innertxt-last{
    margin:0 0 0 50px ;
    font-weight: 400;
    font-weight: bold;
}
.inner-contacticon{
    font-size: 50px;
    margin-left: 70px;
    color: rgb(252, 248, 248);

}
.watsapcon:hover{
color: rgb(24, 228, 17);
}
.instacon:hover{
color: rgb(160, 4, 82);
}
.fbcon:hover{
color: royalblue;
}
.linkcon:hover{
color: royalblue;
}
.maincontact-content{
   
    padding: 30px 0 30px 0;
}
.contact-row{
    height: 500px;
}


.innercon-form{
    margin-top: 60px;
}
.form-contactdiv{
    background-color: rgba(65, 105, 225, 0.575);
}
.cntct-btn{
    font-size: 17px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: slateblue;
    color: white;
}




@media (max-width: 952px) {

  

    .innertxt-head{
        margin-left: 25px;
    }
    .innertxt-content{
        margin-left: 28px;
    }
    .innertxt-last{
        margin-left:30px ;
    }
} */

/* .fulltalk{
    background-image: url(../image/talktous.jpg);
    background-size: 100% 50%;
    background-repeat: no-repeat;
    height: 100vh;
} */
@media screen and (max-width:350px){
    .form-botomtexts{
        width: auto;
    }
    .innercon-form{
        margin-left: unset;
        padding: 10px;
    }
    #hometalk{
        width:auto;
    }
    
    .footheads{
        margin-left: unset;
    }
}
@media screen and (max-width:200px){
    #hometalk{
        width:100px;
    }
    .form-botomtexts{
        width: 100px;
    }
    .footheads{
        margin-left: unset;
    }
    .cntct-btn{
        margin-left: unset;
    }
    
}