
.footer-icon{
    font-size: 25px;
    margin-left: 60px;
    
}
.color{
    color: white;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    cursor: pointer;
}
.footer-content{
    background-color:#6ca6ff;;
    height: 75px;
  
    width: 100%;
    z-index: +3;
    
}
.footer-links{
    text-align: center;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    
}
.Footer-item{
    font-size: 17px;
   font-weight: 500;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    cursor: pointer;
}
.footer-content .footer-link{
    float: right;
    margin-right:-12%;
    text-decoration: none;
     font-size: 17px;
   font-weight: 500;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}
.footer-content .footer-link .Footer-item{
    display: inline-block; 
    
    font-size: 17px;
   font-weight: 500;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    cursor: pointer;
    justify-content:right;
    margin-top: 7%;

}
@media (max-width: 952px) {

    /* .footer-content{
    width: 100%;
    height: 120px;
    
    
    } */
     .slicklg{
        display: flex;
        flex-direction: column;
    } 
}
.slicklg{
    display: flex;
    /* margin-top: 120px; */
}
.slicksm{
    margin-top: 20px;
}
@media (max-width: 752px){
    .footer-links{
        margin:auto;
    }
}