.job-top{
    text-align: center;
    align-items: center;
    margin-top: 170px;
}
.job-tophead{
    font-size: 25px;
    font-weight: 600;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}
.job-position{
    font-size:20px;
 font-weight: 600;
}
.job-exp{
    color: rgba(0, 0, 0, 0.514);
    font-size: 18px;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}
.job-contents{
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
}
.job-img{
    width: 80%;
    height: 250px;
}
.careerrw{
    margin-top: 36px;
}
.crrsec{
    margin-top: 13px;
}
.crrthrd{
    margin-top: 13px;
}
.crrfur{
    margin-top:13px;
}
.crrfv{
    margin-top:13px;
}
@media screen and (max-width: 992px) {
    .job-img{
        width: 100%;
    }

}
.resume{
    text-align: center;
    padding: 14px 0px 20px 0px;
    font-size: 17px;
    font-weight: 500;
    font-family: "Segoe UI",SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
}